import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { Network } from '@capacitor/network';
import { PluginListenerHandle } from '@capacitor/core';
import DeviceService from './device';

export default class NetworkService extends Service {
  // Service injections
  @service device!: DeviceService;

  // Untracked properties
  statusListener?: PluginListenerHandle;

  // Tracked properties
  @tracked isOnline = true;

  // Getters and setters

  // Lifecycle methods
  willDestroy(): void {
    super.willDestroy();

    if (this.statusListener) {
      this.statusListener.remove();
    }
  }

  // Other methods
  async setupStatusListener() {
    if (!this.device.isHybrid) {
      return;
    }

    const status = await Network.getStatus();
    this.isOnline = status.connected;

    this.statusListener = await Network.addListener('networkStatusChange', status => {
      this.isOnline = status.connected;
    });
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    network: NetworkService;
  }
}
